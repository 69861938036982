import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  inject,
  Injector,
  OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { CartService } from './pages/cart-page/cart.service';
import { SpreeService } from './services/spree-client/storefront/spree.service';
import { StateService } from './services/state.service';
import { storeSerializer } from './services/store-serializer';
import { filter, map, skipWhile } from 'rxjs';
import { LocalStorageService } from './services/local-storage.service';
import { LanguagePopupService } from './components/language-popup/language-popup.service';
import { VERSION } from './app.version';
import { CookiebotService } from './services/cookiebot.service';
import { fireAfterFirstLCP } from './helpers/performance';
import { ScriptService } from './services/script.service';
import { IS_BROWSER } from './providers/is-platform.provider';

const luigisBoxUrls = {
  en: 'https://scripts.luigisbox.com/LBX-576475.js',
  sk: 'https://scripts.luigisbox.com/LBX-577888.js',
  cs: 'https://scripts.luigisbox.com/LBX-578586.js',
} as Record<string, string>;

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  template: `<router-outlet></router-outlet>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private readonly cart = inject(CartService);
  private readonly client = inject(SpreeService);
  private readonly state = inject(StateService);
  private readonly localStorage = inject(LocalStorageService);
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);
  private readonly languagePopupService = inject(LanguagePopupService);
  private readonly injector = inject(Injector);
  private readonly cookiebot = inject(CookiebotService);
  private readonly isBrowser = inject(IS_BROWSER);

  ngOnInit() {
    let count = 0;
    this.state.activeLocale$
      .pipe(
        filter(Boolean),
        skipWhile(() => this.isBrowser && count++ < 1),
      )
      .subscribe((locale) => {
        this.updateStore();
        this.updateCulture(locale);
      });

    afterNextRender(
      {
        read: () => {
          fireAfterFirstLCP(() => {
            console.log('Version: ', VERSION);
            this.cookiebot.afterUserInitialChoice().subscribe(this.openPopup);
            this.cart.getCart();
            this.handleChunkLoadingError();
            this.initLuigiAndSynerise();
          });
        },
      },
      { injector: this.injector },
    );
  }

  private initLuigiAndSynerise(): void {
    import('./services/synerise.service').then(({ SyneriseService }) => {
      const locale = this.state.getActiveLocale();
      this.injector.get(SyneriseService).init();
      this.injector.get(ScriptService).createAndAppend({
        id: 'LuigisBox',
        src: luigisBoxUrls[locale],
      });
    });
  }

  private updateCulture(locale: string): void {
    this.document.documentElement.lang = locale;
    const cookiebotScript = this.document.getElementById('Cookiebot');
    cookiebotScript!.setAttribute('data-culture', locale);
    const luigisBoxScript = this.document.getElementById('LuigisBox');
    luigisBoxScript?.setAttribute('src', luigisBoxUrls[locale] ?? luigisBoxUrls['en']);
  }

  private openPopup = (): void => {
    this.languagePopupService.openPopup();
  };

  private updateStore(): void {
    this.client.store
      .show()
      .pipe(map((storeDto) => storeSerializer(storeDto)))
      .subscribe((store) => {
        this.state.setStore(store);
      });
  }

  private handleChunkLoadingError(): void {
    const recoverUrl = this.localStorage.getItem('chunkLoadErrorReloadTarget');

    if (!recoverUrl) {
      return;
    }

    let count = Number.parseInt(this.localStorage.getItem('chunkLoadErrorReloadCount')!);

    if (count > 1) {
      // count > 1 means previous recovery didn't work out
      this.clearChunkLoadingErrorData();

      return;
    }

    count++;
    this.localStorage.setItem('chunkLoadErrorReloadCount', count.toString());
    this.router.navigateByUrl(recoverUrl).then((isSuccessful) => {
      if (isSuccessful) {
        this.clearChunkLoadingErrorData();
      }
    });
  }

  private clearChunkLoadingErrorData(): void {
    this.localStorage.removeItem('chunkLoadErrorReloadTarget');
    this.localStorage.removeItem('chunkLoadErrorReloadCount');
  }
}
