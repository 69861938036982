export function fireAfterFirstLCP(callback: () => void, showLogs = false): void {
  let fallbackTimeout: NodeJS.Timeout | undefined;

  if (PerformanceObserver.supportedEntryTypes.includes('largest-contentful-paint')) {
    const observer = new PerformanceObserver((list) => {
      const entries = list.getEntries() as PerformanceEntry[];
      // Largest Contentful Paint is always the last relevant entry for this observer
      const lcpEntry = entries[entries.length - 1];

      if (lcpEntry) {
        showLogs && console.log('LCP recorded for first navigation:', lcpEntry.startTime);

        if (fallbackTimeout) {
          clearTimeout(fallbackTimeout);
        }

        observer.disconnect();
        callback();
      }
    });

    // Start observing LCP. Use buffered: true to catch events before this code executes
    observer.observe({ type: 'largest-contentful-paint', buffered: true });

    // Set a fallback timeout in case LCP is never recorded
    fallbackTimeout = setTimeout(() => {
      showLogs && console.warn('LCP not recorded in time—executing fallback callback.');
      observer.disconnect();
      callback();
    }, 20000);
  } else {
    showLogs &&
      console.warn(
        'LCP is not supported in this browser. Executing callback immediately.',
      );
    callback();
  }
}
